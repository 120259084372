
$(document).on('turbolinks:load', () => {	//turbolinks
  //$(document).ready(function() {
  let body_data = $('body').data();

  /*global functions */

console.log($('#'+body_data.controller+'_table'))

  $('#'+body_data.controller+'_table').on("click", "td:not(.exclude-click)", function() {
    window.location.href = $(this).parent().data("link")
  });  

  //feather.replace()
  // Eventi
  $(window).trigger(body_data.controller + ':loaded')
  $(window).trigger(body_data.controller + '#' + body_data.action + ':loaded')
  
});