// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
Rails.start();
window.Rails = Rails;
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels")

import 'main';

//import "channels";

//= require("popper.js") if without bundle in bootstrap
//require("jquery");
//require("bootstrap/dist/js/bootstrap.bundle");

//= require_tree .
